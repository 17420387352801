import { StationGroup } from "@app/models/station/station.class";
import { createReducer, on } from "@ngrx/store";
import { STATIONGROUPS_LOAD_FAILURE, STATIONGROUPS_LOAD_SET, STATIONGROUPS_LOAD_SUCCESS, STATIONGROUPS_SET, STATIONGROUP_UPDATE } from "./stationGroups.actions";

export interface StationGroupsState {
  stationGroups: StationGroup[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: StationGroupsState = {
  stationGroups: [],
  loading: false,
  loaded: false
};

export const stationGroupsReducer = createReducer(
  initialState,
  on(STATIONGROUPS_SET, (state, { stationGroups: stationGroups }) => {
    return { ...state, stationGroups: stationGroups };
  }),
  on(STATIONGROUP_UPDATE, (state, { stationGroup }) => {
    const newState = [...state.stationGroups];
    const index = newState.findIndex(s => s.groupId === stationGroup?.groupId);
    if (index !== -1) newState[index] = stationGroup;
    else newState.push(stationGroup);

    return { ...state, stationGroups: newState };
  }),
  on(STATIONGROUPS_LOAD_SET, (state) => {
    return { ...state, loading: true };
  }),
  on(STATIONGROUPS_LOAD_SUCCESS, (state, { stationGroups }) => {
    return { ...state, stationGroups: stationGroups, loading: false, loaded: true };
  }),
  on(STATIONGROUPS_LOAD_FAILURE, (state) => {
    return { ...state, loading: false, loaded: false };
  })
);
