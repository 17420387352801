import { DestroyRef, inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { selectCurrentUserHighestRoleAllocation } from "../store/currentUser/currentUser.selectors";
import { map, skipWhile } from "rxjs";
import AccessHelper from "./access.helper";
import { RoleEnum } from "@app/models/common/roles.enum";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";


export const homeGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  const destroyRef = inject(DestroyRef);

  const currentRole$ = store.select(selectCurrentUserHighestRoleAllocation)

  const result = currentRole$.pipe(
    takeUntilDestroyed(destroyRef),
    skipWhile(role => !role || role == RoleEnum.NoAccess),
    map(role => {
      if (role == RoleEnum.MarketingOperator || role == RoleEnum.Observator) {
        router.navigate(['/newPriceObservations']);
      }
      else if (AccessHelper.checkViewAccess('alarms', role)) {
        router.navigate(['/alarms']);
      } else {
        router.navigate(['changelog']);
      }

      return true;
    })
  );

  return result;
}
