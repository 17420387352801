import { MenuOption } from '@models/menuOption/menuOption.class';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, OnInit, Output, signal, DestroyRef, input, effect, computed, Signal } from '@angular/core';
import { MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { SettlementService } from '@app/core/http/settlement.service';
import { RoleEnum } from '@app/models/common/roles.enum';
import OnboardedHelper from '@app/core/helpers/onboarded.helper';
import { TranslatePipe } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReconciliationStatus } from '@app/models/settlement/settlement.class';
import AccessHelper from '@app/core/helpers/access.helper';

@Component({
  selector: 'app-layout-menu-section',
  templateUrl: './layout-menu-section.component.html',
  styleUrls: ['./layout-menu-section.component.scss'],
  standalone: true,
  imports: [NgClass, MatTreeModule, MatButtonModule, RouterLink, MatIconModule, MatBadgeModule, TranslatePipe],
  animations: [
    trigger('slideVertical', [
      state('*', style({ height: 0 })),
      state('show', style({ height: '*' })),
      transition('* => *', [animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)')])
    ])
  ]
})
export class LayoutMenuSectionComponent implements OnInit {
  treeSource = new MatTreeNestedDataSource<MenuOption>();
  menuOptions = input.required<MenuOption[]>();
  menuTitle = input<string>('');
  isHidden = input<boolean>(true);
  filteredMenuOptions: Signal<MenuOption[]> = computed(() =>
    this.onboardedHelper.filterMenu(this.menuOptions()).filter(x => this.checkHasRole(x.roleAccess))
  );
  currentRole = input.required<RoleEnum>();

  @Output() closeMenuEvent = new EventEmitter();
  treeControl = new NestedTreeControl<MenuOption>(node => node.subOptions);
  badgeCount = signal<string | number>(0);

  constructor(
    private settlementService: SettlementService,
    private onboardedHelper: OnboardedHelper,
    private destroyRef: DestroyRef,
    private router: Router
  ) {
    // Update the deviations count if the deviations option is available and the user has access to view deviations
    effect(() => {
      if (this.filteredMenuOptions().some(x => x.redirectUrl === "/deviations") && AccessHelper.checkViewAccess("deviations", this.currentRole())) {
        this.getDeviationsCount();
      }
    });
    // Update the tree source when the filtered menu options change
    effect(() => this.treeSource.data = this.filteredMenuOptions());
  }

  ngOnInit() {
    // Ensure that the current menu option is expanded if a sub option is the current route (particularly for refreshes)
    this.router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      const currentUrl = this.router.url.split('?')[0]; // Remove query params from url
      const subOptionOpen = this.filteredMenuOptions().find(x => x.subOptions?.some(y => y.redirectUrl === currentUrl));
      if (subOptionOpen) this.treeControl.expand(subOptionOpen);
    });
  }

  getBadgeColor = () => {
    if (localStorage.getItem('theme') === 'dark') return 'accent';
    return 'primary';
  }

  checkAnyOptionHasRole = (roles: RoleEnum[]) => this.filteredMenuOptions().some(x => x.roleAccess.some(y => roles.includes(y)));
  checkHasRole = (roles: RoleEnum[]) => roles.includes(this.currentRole());
  closeMenuEventEmit = () => this.closeMenuEvent.emit();
  isCurrentActiveMenuItem = (url: string) => window.location.pathname == url;
  nodeHasChild = (_: number, node: MenuOption) => !!node.subOptions && node.subOptions.length > 0;
  getDeviationsCount = () => {
    this.settlementService.getAllSettlementsByStatus(ReconciliationStatus.Failed).subscribe(res => {
      this.badgeCount.set(res.totalCount > 100 ? '!' : res.totalCount);
    });
  }
}
