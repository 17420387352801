import { InfluenceRegion } from "@app/models/price/influenceRegion.class";
import { createReducer, on } from "@ngrx/store";
import { INFLUENCEREGIONS_INVALIDATE, INFLUENCEREGIONS_SET, INFLUENCEREGIONS_LOAD_FAILURE, INFLUENCEREGIONS_LOAD_SET, INFLUENCEREGIONS_LOAD_SUCCESS } from "./influenceRegions.actions";

export interface InfluenceRegionsState {
  influenceRegions: InfluenceRegion[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: InfluenceRegionsState = {
  influenceRegions: [],
  loading: false,
  loaded: false
};

export const influenceRegionsReducer = createReducer(
  initialState,
  on(INFLUENCEREGIONS_SET, (state, { influenceRegions }) => {
    return { ...state, influenceRegions: influenceRegions };
  }),
  on(INFLUENCEREGIONS_LOAD_SET, (state) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(INFLUENCEREGIONS_LOAD_SUCCESS, (state, { influenceRegions }) => {
    return { ...state, influenceRegions: influenceRegions, loading: false, loaded: true };
  }),
  on(INFLUENCEREGIONS_LOAD_FAILURE, (state) => {
    return { ...state, loading: false, loaded: false };
  }),
  on(INFLUENCEREGIONS_INVALIDATE, (state) => {
    state = initialState;
    return state;
  })
);
