import { inject } from "@angular/core";
import { StationService } from "@app/core/http/station.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { STATIONS_FETCH, STATIONS_LOAD_FAILURE, STATIONS_LOAD_SET, STATIONS_LOAD_SUCCESS } from "./stations.actions";
import { EMPTY, catchError, map, mergeMap, of, tap } from "rxjs";
import { concatLatestFrom } from "@ngrx/operators";
import { get_stations, get_stationsLoading as get_stationsLoading } from "./stations.selectors";
import { APIerror } from "@app/models/common/error.class";

export const loadStations$ = createEffect(
  (
    $actions = inject(Actions),
    stationService = inject(StationService),
    store = inject(Store)
  ) => {
    return $actions.pipe(
      ofType(STATIONS_FETCH),
      concatLatestFrom(() => [
        store.pipe(select(get_stations())),
        store.pipe(select(get_stationsLoading()))
      ]),
      // First element of the array is the action, which isn't needed. Empty and unnamed value to not trigger linting errors
      mergeMap(([, stations, loading]) => {
        // Stations already loaded
        if (stations.length) {
          return of(STATIONS_LOAD_SUCCESS({ stations: stations }))
          // Stations are loading
        } else if (loading) {
          return EMPTY;
        }

        // Load stations
        store.dispatch(STATIONS_LOAD_SET());
        return stationService.getAllStations().pipe(
          tap(() => store.dispatch(STATIONS_LOAD_SET())),
          map(result => STATIONS_LOAD_SUCCESS({ stations: result.items })),
          catchError((error: APIerror) => of(STATIONS_LOAD_FAILURE({ error: error })))
        )
      })
    )
  }, { functional: true }
)
