import { RoleAllocation } from "./roleAllocation.class";

export class User {
  id!: string;
  fullName!: string;
  phoneNumber!: string;
  emailAddress!: string;
  allocations!: RoleAllocation[];
  identities!: Identity[];

  public constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}

export class Identity {
  issuer!: string;
  issuerAssignedId!: string;
}

export class SimpleUser {
  id!: string;
  fullName!: string;

  public constructor(init?: Partial<SimpleUser>) {
    Object.assign(this, init);
  }
}
