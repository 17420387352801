import { APIerror } from '@models/common/error.class';
import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { USER_FETCH_BY_ID, USER_LOAD_FAILURE, USER_LOAD_SUCCESS, USER_LOAD_SET } from "./user.action";
import { EMPTY, catchError, map, mergeMap, of, tap } from "rxjs";
import { Store, select } from "@ngrx/store";
import { get_userById, get_userLoading } from "./user.selector";
import { concatLatestFrom } from '@ngrx/operators';
import { AuthorisationService } from '@app/core/http/authorisation.service';

export const loadUser$ = createEffect(
  (
    $actions = inject(Actions),
    authorisationService = inject(AuthorisationService),
    store = inject(Store)
  ) => {
    return $actions.pipe(
      ofType(USER_FETCH_BY_ID),
      concatLatestFrom(action => [
        store.pipe(select(get_userById(action.userId))),
        store.pipe(select(get_userLoading(action.userId))),
      ]),
      mergeMap(([action, user, loading]) => {
        // User already loaded
        if (user) {
          return of(USER_LOAD_SUCCESS({ user }));
          // User is loading || doesn't exist
        } else if (loading) {
          return EMPTY;
        }
        // Load user
        store.dispatch(USER_LOAD_SET({ id: action.userId }));
        return authorisationService.getUsersSimple([action.userId]).pipe(
          tap(() => store.dispatch(USER_LOAD_SET({ id: action.userId }))),
          map(response => response?.items?.length ? USER_LOAD_SUCCESS({ user: response.items[0] }) : USER_LOAD_FAILURE({ error: { status: 404 }, userId: action.userId })),
          catchError((error: APIerror) => of(USER_LOAD_FAILURE({ error: error, userId: action.userId }))),
        );
      })
    )
  }, { functional: true }
);
