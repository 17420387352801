import { createReducer, on } from '@ngrx/store';
import { User } from '@app/models/authorisation/user.class';
import {
  setUserInformation,
  setUserName,
  setUserPermissions,
} from './currentUser.actions';

export const initialState: User = new User();

export const currentUserReducer = createReducer(
  initialState,

  on(setUserInformation, (state, { user }) => {
    if (!user.id) return state;

    return {
      id: user.id,
      fullName: user.fullName,
      identities: user.identities,
      emailAddress: user.emailAddress,
      phoneNumber: user.phoneNumber,
      allocations: user.allocations,
    };
  }),

  on(setUserName, (state, { fullName }) => {
    return { ...state, fullName: fullName };
  }),

  on(setUserPermissions, (state, { allocations }) => {
    return { ...state, allocations: allocations };
  })
);
