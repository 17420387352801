import { CompetitorStation } from "@app/models/price/priceCompetitors.class";
import { COMPETITORSTATIONS_INVALIDATE, COMPETITORSTATIONS_LOAD_FAILURE, COMPETITORSTATIONS_LOAD_SET, COMPETITORSTATIONS_LOAD_SUCCESS, COMPETITORSTATIONS_SET } from "./competitorStations.actions";
import { createReducer, on } from "@ngrx/store";

export interface CompetitorStationsState {
  competitorStations: CompetitorStation[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: CompetitorStationsState = {
  competitorStations: [],
  loading: false,
  loaded: false
};

export const competitorStationsReducer = createReducer(
  initialState,
  on(COMPETITORSTATIONS_SET, (state, { competitorStations }) => {
    return { ...state, competitorStations: competitorStations };
  }),
  on(COMPETITORSTATIONS_LOAD_SET, (state) => {
    return { ...state, loading: true };
  }),
  on(COMPETITORSTATIONS_LOAD_SUCCESS, (state, { competitorStations }) => {
    return { ...state, competitorStations: competitorStations, loading: false, loaded: true };
  }),
  on(COMPETITORSTATIONS_LOAD_FAILURE, (state) => {
    return { ...state, loading: false, loaded: false };
  }),
  on(COMPETITORSTATIONS_INVALIDATE, (state) => {
    state = initialState;
    return state;
  })
);
