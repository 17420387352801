import { enableProdMode, LOCALE_ID, DEFAULT_CURRENCY_CODE, importProvidersFrom } from '@angular/core';
import { DATE_PIPE_DEFAULT_OPTIONS, DatePipe, DecimalPipe } from '@angular/common';
import { withInterceptorsFromDi, provideHttpClient, HttpClient, withInterceptors } from '@angular/common/http';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';

// Store
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import * as userEffects from '@app/core/store/user/user.effects';
import * as stationEffects from '@app/core/store/stations/stations.effects';
import * as stationGroupEffects from '@app/core/store/stationGroups/stationGroups.effects';
import * as fuelEffects from '@app/core/store/fuels/fuels.effects';
import * as competitorStationsEffects from '@app/core/store/competitorStations/cometitorStations.effects';
import * as influenceRegionsEffects from '@app/core/store/influenceRegions/influenceRegions.effects';
import { usersReducer } from '@app/core/store/user/user.reducer';
import { stationsReducer } from '@app/core/store/stations/stations.reducer';
import { fuelsReducer } from '@app/core/store/fuels/fuels.reducer';
import { stationGroupsReducer } from '@store/stationGroups/stationGroups.reducer';
import { currentUserReducer } from '@store/currentUser/currentUser.reducer';
import { competitorStationsReducer } from '@app/core/store/competitorStations/competitorStations.reducer';
import { influenceRegionsReducer } from '@app/core/store/influenceRegions/influenceRegions.reducer';

// Authorization
import { AuthGuard, AuthHttpInterceptor, authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';

import { environment } from '@environments/environment';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app-routing.module';
import { TankService } from './app/core/http/tank.service';
import { StationService } from './app/core/http/station.service';
import { SettlementService } from './app/core/http/settlement.service';
import { PriceService } from './app/core/http/price.service';
import { OnboardingService } from './app/core/http/onboarding.service';
import { MarkdownService } from './app/core/http/markdown.service';
import { FuelService } from './app/core/http/fuel.service';
import { DocumentService } from './app/core/http/document.service';
import { AuthorisationService } from './app/core/http/authorisation.service';
import { NotificationService } from '@app/core/http/notification.service';
import { AlarmService } from '@app/core/http/alarm.service';

// Material
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_ICON_DEFAULT_OPTIONS } from '@angular/material/icon';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// Localization
import { TranslateService, TranslateLoader, provideTranslateService } from '@ngx-translate/core';
import { PaginatorIntl } from './assets/i18n/paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeNb from '@angular/common/locales/nb';
import { norwegianDateAdapterProvider } from '@app/core/adapters/norwegian-date-adapter';
import { NetworkService } from '@app/core/http/network.service';

registerLocaleData(localeNb, 'nb');

function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

if (environment.production) {
  enableProdMode();
}



bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(AppRoutingModule), // Check if this can be swapped with provideRouter(routes) when MSAL is ditched
    provideAnimations(),
    // i18n module
    // - setup guide at https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular-app-with-ngx-translate
    // - see guide for swapping language in runtime
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    provideStore({
      currentUser: currentUserReducer,
      fuels: fuelsReducer,
      stationGroups: stationGroupsReducer,
      stations: stationsReducer,
      users: usersReducer,
      competitorStations: competitorStationsReducer,
      influenceRegions: influenceRegionsReducer
    }),
    provideEffects(
      userEffects,
      stationEffects,
      stationGroupEffects,
      fuelEffects,
      competitorStationsEffects,
      influenceRegionsEffects
    ),
    // Authorization
    AuthGuard,
    provideAuth0({
      ...environment.auth0,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          `${environment.routes.apiEndpoint}/*`,
        ]
      }
    }
    ),
    AuthHttpInterceptor,
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
    // Locale
    norwegianDateAdapterProvider(),
    { provide: LOCALE_ID, useValue: "nb-NO" },
    { provide: DEFAULT_CURRENCY_CODE, useValue: "NOK" },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'dd.MM.yy | HH:mm' } },
    /**
     * seconds: 'dd.MM.yy | HH:mm:ss',
     * minutes: 'dd.MM.yy | HH:mm',
     * only date (longer): 'dd. MMMM yy'
     * only time: 'HH:mm'
     */
    { provide: MAT_DATE_LOCALE, useValue: "nb-NO" },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorIntl(translateService).getPaginatorIntl()
    },
    // Default material options
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 500, hideDelay: 0, touchendHideDelay: 0, disableTooltipInteractivity: true, position: 'above' } },
    { provide: MAT_ICON_DEFAULT_OPTIONS, useValue: { fontSet: 'material-symbols-outlined' } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    // Pipes
    DatePipe,
    // Services
    AlarmService,
    AuthorisationService,
    DecimalPipe,
    DocumentService,
    FuelService,
    MarkdownService,
    NetworkService,
    NotificationService,
    OnboardingService,
    PriceService,
    SettlementService,
    SettlementService,
    StationService,
    TankService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideEffects()
  ]
})
  .catch(err => console.error(err));
