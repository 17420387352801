import { createReducer, on } from "@ngrx/store";
import { APIerror } from "@models/common/error.class";
import { USER_ADD, USERS_SET, USER_UPDATE, USER_LOAD_SET, USER_LOAD_SUCCESS, USER_LOAD_FAILURE } from "./user.action";
import { SimpleUser } from "@app/models/authorisation/user.class";

export interface UserState {
  users: SimpleUser[];
  loading: Record<string, boolean>;
}

export const initialState: UserState = {
  users: [],
  loading: {}
};

export const usersReducer = createReducer(
  initialState,
  on(USERS_SET, (state, { users }) => {
    return { ...state, users: users };
  }),
  on(USER_ADD, (state, { user }) => {
    const newState = { ...state, users: [...state.users] };
    newState.users.push(user);

    return newState;
  }),
  on(USER_UPDATE, (state, { user }) => {
    const newState = { ...state, users: [...state.users] };
    const index = newState.users.findIndex(u => u.id === user?.id);
    if (index !== -1)
      newState.users[index] = user;
    else
      newState.users.push(user);

    return newState;
  }),
  on(USER_LOAD_SET, (state, { id }) => {
    return { ...state, loading: { ...state.loading, [id]: true } };
  }),
  on(USER_LOAD_SUCCESS, (state, { user }) => {
    const newState = { ...state, users: [...state.users], loading: { ...state.loading } };
    const index = newState.users.findIndex(u => u.id === user.id);
    if (index !== -1) {
      newState.users[index] = user;
    } else {
      newState.users.push(user);
    }
    newState.loading[user.id] = false;

    return newState;
  }),
  on(USER_LOAD_FAILURE, (state, { error, userId }: { error: APIerror, userId: string }) => {
    const newState = { ...state, users: [...state.users], loading: { ...state.loading } };
    newState.loading[userId] = false;

    // User not found, add empty user
    if (error.status === 404) {
      const index = newState.users.findIndex(u => u.id === userId);
      if (index === -1) {
        newState.users.push(new SimpleUser({ id: userId }));
      }
    }

    return newState;
  })
);
